:root {
  --primary-color: #ffb6c1;
  --secondary-color: #add8e6;
}

html {
  margin: 0;
  padding: 0;
  overflow: hidden;
  scroll-behavior: smooth;
  width: 100vw;
  height: 100vh;
}

body {
  position: fixed;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  width: 100vw;
  background: url(https://source.unsplash.com/random) no-repeat center center fixed;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  width: 900px;
  max-width: 85vw;
}

h1 {
  color: var(--primary-color);
  font-size: 36px;
  margin-bottom: 10px;
}

p {
  color: var(--secondary-color);
  font-size: 24px;
  margin-bottom: 20px;
}

input[type="text"] {
  padding: 10px;
  border-radius: 5px;
  border: none;
  margin-right: 10px;
  width: 70%;
  outline: none !important;
  background-color: rgba(255, 255, 255, 0.5);
}

button {
  width: calc(30% - 30px);
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: var(--primary-color);
  color: black;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
}

iframe {
  margin-top: 20px;
  border: none;
  width: 100%;
  height: 300px;
  opacity: 0.8;
  border-radius: 10px;
  filter: hue-rotate(135deg);
}